body:has(.SwalHolder){
    background: none !important;
}

.TransferImage {
    background-color: black;
    border-radius: .5em;
    margin: 0 .5em;
}

.TransacTable {
    border-spacing: 0 !important;
    padding: .5em 0 ;
    font-size: .85em;
}

.SuccessDesc {
    font-size: .85em;
}

.SuccessTTitle {
    font-size: .80em;
}

.SuccessTSubTitle {
    font-size: .75em;
}

.TransaContainer {
    margin: 0 auto;
}

.TransacTable tr td:nth-child(odd){
    background-color: #9B9B9B;
    color: white;
}

.TransacTable tr td{
    padding: .5em;
    border: 1px solid black;
}

.ContainerSuccessT {
    display: flex;
    margin: 1em .2em;
}

@media only screen and (max-width: 608px) {
    .ContainerSuccessT {
        display: flex;
        flex-direction: column;
    }

    .payContent {
        font-size: .95em;
    }

    .TransferImage {
        margin: 0 auto;
    }

    .SuccessDesc {
        font-size: .8em;
    }
}