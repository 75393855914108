body:has(.FormContainer) {
    background: #f0f4f9 !important;
}

.LogoContainerSubmit {
    display: flex;
    justify-content: flex-start;
    width: 10% !important;
}

.FormOuterContainer { 
    border-radius: 0 1em 1em 1em;
    padding: 1em;
    background-color: white;
}

.MessageContainer {
    display: flex !important;
}

.MessageContainer svg {
    width: 1.5em !important;
    margin: 0 .5em !important;
    height: 1.5em !important;
}

.FormDivideTab {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.SelectedTab {
    background-color: white !important;
}

.FormDivideTab li:hover {
    cursor: pointer;
}

.FormDivideTab li {
    background-color: #c0c0c0;
    border-radius: 1em 1em 0 0;
    font-weight: bold;
    padding: 1em;
}

.LogoSubmit {
    background-size: 70% !important;
    background-color: black;
    border-radius: 8em;
}

.buttonSubmitCont {
    display: flex;
    margin-top: 2em;
    justify-content: flex-end;
}

.buttonSubmitCont button,.BackButtonHome{
    padding: 1em 3em;
    border-radius: .5em;
    margin: 0 .5em;
    font-weight: 700;
    border:0px solid transparent;
}

.FormSubmitContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
}

.FormExp {
    width: 90%;
    padding: 1em .5em;
}

.FormTitle {
    font-size: 1.5em;
    font-weight: 600;
    margin: .1em 0;
}

.HolderForm {
    display: flex;
    width: 100%;
}

.FormContainer{
    display: flex;
    width: 100%;
}

.SubmitImage {
    background-image: url("../../public/image/miscellanous/default.jpg");
}

.SubmitImage{
    padding: 5em;
    background-size: cover;
    background-position: center;
    border-radius: .2em;
}

.FeeCont {
    padding: .5em;
}

.selectedFile {
    display: none;
}

.swalPaymentForm .swal2-actions {
    margin: 0 !important;
    padding-bottom: .5em !important;
}

.swalPaymentForm div .swal2-html-container {
    padding: .5em !important;
}

.swalPaymentForm div {
    padding: 0 !important;
}

.ImageContainerMemberFee button:hover { 
    cursor: pointer;
}

.FeeCont {
    text-align: left;
}

.ImageContainerMemberFee button {
    padding: .5em 2em;
    border-radius: .5em;
    border:.2px solid black;
    font-weight: bold;
}

.ImageContainerMemberFee {
    display: flex;
    justify-content: right;
}

.ContactInfo,.ImageContainer{
    margin: .5em 0;
}

.CredentialContainer {
    margin: auto 0;
}

.EmploymentContainer,.ContactInfo,.PaymentCont,.RegisterCont,.SuggestionSect {
    display: none;
}

.CredentialContainer,.EmploymentContainer,.ContactInfo,.PaymentCont,.RegisterCont,.SuggestionSect{
    flex-direction: column;
    width: 100%;
}

.CredentialContainer div input {
    margin-right: 1em !important;
    width: 85% !important;
}

.IconHolder input, .IconHolder select {
    width: 100% !important;
}

.successMessage {
    border-left: 5px solid green !important;
}

.swalPopupForm {
    display: flex !important;
    padding: 0 !important;
    border-left: 5px solid red;
    width: 20em !important;
}

.InvalidPict {
    padding-bottom: 1em;
}

.swalContainerForm{
    background-color: transparent !important;
}

.ListEmpty,.errorMessage {
    text-align: left;
    font-size: .75em;
}

.validateEmail {
    margin: auto 0;
}

.swalTitleForm {
    color: black;
    background-color: transparent;
}

.LogoImage { 
    background-size: 60% !important;
    background-image: url('../../public/image/miscellanous/logo.png');
    background-repeat: no-repeat;
    padding: 2em;
    background-position: center;
    width: 10%;
}

.FilePict:hover,.buttonSubmitCont button:hover,.BackButtonHome:hover{
    transform: scale(1.025);
    transition: .2s ease;
    cursor: pointer;
}

.swalForm {
    background-color: black;
}

.FilePict { 
    width: 100%;
    padding: .5em 0;
    color: white;
    background-color: #6C757D;
    font-weight: bold;
    border: 0px solid transparent;
}

.IconHolder input, .IconHolder select {
    padding: .45em;
    width: auto;
    margin: .5em 0;
}

.ImageFile input[type=button]{
    width: 100% !important;
    margin: 0 !important;
}

.ImageFile { 
    margin: 0 !important;
    display: block !important;
}

.IconHolder {
    display: flex;
    margin: 0 1em;
    justify-content: flex-end;
}

.buttonSubmitCont button{
    background-color: #427BFF;
    color: white;
}

.buttonSubmitCont button {
    display: none;
}

.FormContainedContainer {
    margin: 10em auto;
    width: 70%;
}

.FormDoubleContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
}

.IconHolder div{
    background-color: #c0c0c0;
    padding: .3em;
    border-radius: .2em 0 0 .2em;
    margin: auto 0;
}

.FormHeader {
    flex-direction: row-reverse !important;
}

.ContainerBackButtonHome {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 1em;
}

.BackButtonHome {
    margin: auto 0;
}

@media only screen and (max-width: 600px) {
    /* * {
        border: 2px solid red
    } */
    .FormTab {
        display: none !important;
    }
    
    .LogoHeader {
        margin: 0 0.5em;
    }

    .FormExp {
        padding: 1em 0;
    }

    .successMessage {
        border: 0 !important;
        border-top: 5px solid green !important;
        justify-content: center;
    }

    .errorMessage {
        text-align: center !important;
    }

    .MessageContainer {
        font-size: 1.15em;
        flex-direction: column !important;
        justify-content: center;
    }

    .swalPopupForm { 
        border: 0 !important;
        border-top: 5px solid red !important;
    }

    .FormContainedContainer {
        margin: 5em 0;
        width:100%;
    }
    
    .swalContainerForm { 
        background:rgba(0,0,0,0.25) !important;
    }

    .HolderForm {
        display: flex;
        flex-direction: column;
    }

    .LogoContainerSubmit {
        margin: 0 auto;
        width: 17% !important;
    }

    .FormExp{
        text-align: center;
        width: 100%;
        font-size: .85em;
    }

    .FormSubmitContainer {
        flex-direction: row;
        justify-content: space-between;
    }

    .MessageContainer svg {
        margin: 1em auto !important;
        width: 5em !important;
        height: 5em !important;
    }

    .FormContainer {
        display: flex;
        flex-direction: column;
    }

    .FormOuterContainer {
        width: 100%;
        background-color: #f0f4f9;
        padding: 0;
    }

    .buttonSubmitCont { 
        justify-content: center;
    }

    .CredentialContainer, .AddressContainer, .ContactInfo, .PaymentCont,.SuggestionSect{
        width: 100%;
    }

    .CredentialContainer div input {
        width: 100% !important;
    }

    .IconHolder { 
        justify-content: flex-start;
    }

    .IconHolder input ,.IconHolder select{
        width: 100% !important;
    }

    .ImageContainer {
        width: 60%;
        margin: auto;
    }
}