.headCont {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.NavigationCont {
    display: flex;
    background-color: #c0c0c0   ;
    flex-direction: column;
    width: 20%;
    justify-content: space-between;
}

.SwalIcon {
    display: flex;
}

.SwalIcon .errorMessage {
    margin: auto 1em;
}

.SwalIcon svg {
    width: 1.5em;
    height: 1.5em;
}

.Copyright {
    text-align: center;
    margin: .5em 0;
    font-size: .80em;
}

.subTitleList,.subListMenuApps li,.ListMenu li{
    display: flex;
    font-size: .95em;
}

.swal2-html-container {
    margin: .65em !important;
    font-weight: bold !important;
}

.subTitleList div, .subListMenuApps li div,.ListMenu li div{
    padding: 0 .2em;
}

.subTitleList svg,.subListMenuApps li svg, .ListMenu li svg{
    margin: auto 0;
}

.LogoutButton {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.LogoutButton button {
    margin: auto;
    border-radius: .5em;
    width: 80%;
    border:0px solid transparent;
    padding: .75em 0;
    background-color: #427bff;
    font-weight: bold;
    color: white;
}

.LogoutButton button:hover{
    cursor: pointer;
    transform: scale(1.015);
    transition:.2s ease;
}

.swal2-title {
    background-color: #6c757d;
    color: white;
    border-radius: .2em .2em 0 0;
    padding: .5em;
}

.ChangePassword div {
    margin: .25em 0;
}

.ChangePassword input {
    padding: .35em;
    width: 100%;
    border: .2px solid black;
}

.swal2-title {
    font-size: 1.5em;
}

.swal2-confirm, .swal2-cancel {
    font-weight: bold !important;
}

.CPCont {
    display:flex;
}

.swalCheckPassForm {
    margin: 1em .5em !important;
    background: transparent !important;
}

.ChangePassword select {
    width: 100%;
}

.ChangePassword label {
    text-align: left;
    font-weight: bold;
    font-size: .85em;
    border-radius: .5em 0 0 .5em;
    padding: .4em .5em;
    margin: auto 0;
    background-color: #c0c0c0;
}

.ChangePassword {
    display: flex;
    width: 50%;
    margin: 0 auto;
    flex-direction: column;
}

html, body, #root,.LogContainer {
    height: 100% !important;
}

.ListMenu,.subListMenuApps {
    padding: 0;
    margin: 0;
}

.ListMenu li:has(.subListMenuApps){
    padding: 0;
}

.subListMenuApps li a,.ListMenu li a {
    text-decoration: none;
    color: black;
    display: flex;
}

.subListMenuApps li {
    padding: .5em 2em !important;
}

.ListMenu li:nth-child(n+2):hover,.subListMenuApps li:hover,.Selected {
    cursor:pointer;
    background-color: azure !important;
}

.ListMenu li, .subTitleList{ 
    list-style-type: none;
    padding: .5em 1em;
    background-color: #c0c0c0;
    font-weight: bold;
}

.LogoTitle { 
    color: white;
    font-weight: bold;
    align-content: center;
    padding: .5em 0;
}

.LogContainer {
    justify-content: flex-start;
    display: flex;
}

.LogoImage {
    background-position: center;
}

.ContentContainer {
    width: 80%;
}

.ContImage {
    background-color: black;
    padding: 1em 0;
    display:flex;
}

.LogoImage { 
    background-size: 60% !important;
    background-image: url('../public/image/miscellanous/logo.png');
    background-repeat: no-repeat;
    padding: 2em;
    width: 10%;
}