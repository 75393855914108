body:has(.HomepageContainer){
    background: none !important;
}

html {
    scroll-behavior: smooth;
}

.headerMain {
    width: 100%;
    background-color: black;
    padding: 1em 0;
    justify-content: space-between;
    position:relative;
    z-index: 5;
    display: flex;
}

.ListMainMenu {
    margin: auto 0;
}

.CopyrightDisclaim {
    text-align: center;
    padding: 1em 0;
    display: flex;
    justify-content: center;
}

.MainLogo {
    width: 5% !important;
    background-size: 50% !important;
}

.SignUpCont {
    background-color: white !important;
    overflow: hidden;
}

.LogoContainerMain,.SignUpCont {
    display: flex;
}

.LogoTitleMain {
    color: white;
}

.ListHeader {
    color: white;
    display: flex;
    list-style-type: none;
    margin: 0 1em !important;
}

.ListHeader li:hover {
    font-weight: bold;
    cursor: pointer;
}

.ListHeader li {
    margin: 0 .5em;
    padding: .5em 0;
}

.ImageFull {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.LogoDesc {
    margin: auto 0;
}

.pd_x_10 {
    padding: 10em 0;
}

.HomepageContainer .ImageContainerH {
    margin: 0 !important;
}

.BackImage {
    background-size: cover !important;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .25;
    background-image: url("../../public/image/miscellanous/backImage.jpeg");
}

.AboutUsMain {
    padding: 2.5em 0;
    position:relative;
}

.AboutUsMain{
    background-color: #EEEEEE;
}

.GreetT {
    font-weight: bold;
    font-size: 1.5em;
    color: #E6342D;
}

.WebName {
    font-weight: bold;
    font-size: 2.3em;
}

.VisiMisi b,.Objektif b {
    font-size: 1.15em;
}

.WebDesc {
    width: 50%;
    display: flex;
    text-align: left;
    margin: 0 auto;
    line-height: 1.5;
}

.FounderPLG {
    width: 25%;
    padding: 20em 0;
    background-image: url('../../public/image/miscellanous/Founder.jpeg');
}

.MainTitle {
    position: relative;
    z-index: 6;
    text-align: center;
}

.Organization {
    display: flex;
}

.ChartTitle {
    font-size: 1.5em;
    font-weight: bold;
}

.LogoGreet {
    background-color: black;
    margin: 2em auto;
    border-radius: 1em;
    padding: 5em 4em !important;
}

.DescOrgs {
    padding: 1em;
}

.ActivityImage {
    padding: 5em;
    border-radius: .5em 0 0 .5em;
}

.ActivityContainer:hover {
    transform: scale(1.025);
    transition: .2s ease;
}

.ActivityContainer {
    display: flex;
    margin: .5em;
    width: 30%;
    box-shadow: 0px 0px 10px 0px rgb(0,0,0,0.25);
    border-radius: .5em;
}

.ActivityDetail {
    font-size: .75em !important;
    text-align: justify !important;
}

.SignUpDesc {
    margin: 1em 0;
}

.SourceIcon {
    text-align: right;
}

.ActivityHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #EEEEEE;
    padding: 2em 0;
}

.ActivityRightCont {
    background-color: white;
    border-radius: .5em;
}

.ActivityTitle {
    font-size: 1em !important;
    font-weight: bold;
}

.ActivityDesc {
    margin: auto 0;
    padding: 1em;
}

.ActivityDesc div { 
    margin: .45em 0;
    font-size: .85em;
}

.EventsMain {
    width: 100%;
}

.EventHolder { 
    line-height: 1.5;
    text-align: center;
}

.SignupImage {
    padding: 10em;
    background-image: url('../../public/image/miscellanous/SignUp.jpg');
    width: 35%;
}

.IconDevelopment {
    padding: 3em;
    margin: 0 auto;
    background-image: url('../../public/image/miscellanous/Construction.png');
    width: 0;
}

.UnderDevelopment {
    padding: 10em 0;
    background-color: #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DevText {
    margin: 1em 0;
    font-size: 1.25em;
    font-weight: bold;
}

.DevText,.DevDesc {
    text-align: center;
}

.DevContainer {
    margin: 0 auto;
}

.SignUpDesc {
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
    line-height: 1.25;
    padding: 0 3.5em;
}

.SignUpTitle {
    font-size: 1.95em;
    font-weight: bold;
}

.SignUpDisclaimer {
    font-size: .85em;
    font-style: italic;
}

.SignUpButton {
    margin: 1.25em 0 .5em 0;
    display: flex;
    justify-content: flex-end;
}

.EmailCont {
    margin: 0 0 0.5em 0;
}

.EmailValidate {
    margin: 0.25em 0;
    font-size: .85em;
}

.EmailConfirmation {
    font-size: .75em;
}

.EmailCont input {
    padding: 0.5em;
}

.emailVerifyCont {
    margin: 0 !important;
}

.successEmail {
    width: 30%;
    padding: 0 !important;
}

.errorEmail {
    font-size: .95em;
    width: 30%;
    padding: 0 !important;
    border-left: .25em solid red;
}

.ChangeCredButt {
    font-size: .85em;
}

.ChangeCredButt:hover {
    cursor: pointer;
    color: #5a5a5a
}



.RegisterNowButt{
    padding: .8em 1.5em;
    text-decoration: none;
    border:0px solid transparent;
    width: 40%;
    background-color: #427BFF;
    color: white;
    border-radius: .5em;
    font-weight: bold;
    font-size: .95em;
}

.footerMain {
    background-color: black;
    display: flex;
    flex-direction: column;
    color: white;
}

.footerMain {
    display: flex;
    justify-content: center;
}

.ListFooter,.ListSocialMedia{
    margin: 0 !important;
    padding: 0 !important;
}

.ListFooter li,.ListSocialMedia li{
    list-style-type: none;
    padding: .25em 0;
}

.ListSocialMedia li a,.ListFooter li a,.ListHeader li a {
    text-decoration: none;
    color: white;
}

.footerContent {
    display: flex;
    justify-content: flex-end;
}

.ListMenuFTitle {
    font-size: 1.15em;
    font-weight: bold;
    margin:.5em 0;
}

.LocationDesc {
    line-height: 1.5;
}

.OfficeLocation {
    width: 30%;
}

.ListMenuFooter, .OfficeLocation {
    margin: 0 1em;
}

.footerHoldCont {
    padding: 3em 1.25em;
    display: flex;
    justify-content: space-around;
}

.RegisterNowButt:hover {
    cursor: pointer;
    transform: scale(1.025);
    transition: .2s ease;
}

.LogoContClic {
    width: 5em;
    height: 5em;
}

.SignUpButton a {
    width: 100%;
    margin: .6em 0;
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .ActivityContainer {
        flex-direction: column !important;
    }

    .SignUpTitle {
        font-size: 1em;
    }

    .WebDesc {
        flex-direction: column;
    }

    .Objektif {
        margin: 1em 0;
    }
}


@media only screen and (max-width: 600px) {
    /* * {
        border: 2px solid red;
    } */
     body {
        overflow: hidden;
     }

     .SignUpButton{
        font-size: .95em;
        justify-content: left;
        margin: .6em .5em;
     }

     .ChangeCredButt {
        margin: 0 1em;
        font-size: .7em;
     }

     .emailDesc {
        font-size: .85em;
     }

     .EmailValidate {
        font-size: .8em
     }

     .EmailConfirmation {
        font-size: .7em;
     }

     .successEmail{
        border-left:0;
        border-top:.25em solid green;
     }

     .errorEmail{
        border-left:0;
        border-top:.25em solid red;
     }

     .successEmail,.errorEmail {
        width: 100%;
     }

    .AboutUsMain,.ActivityContainer,body,.WebDesc,.OfficeLocation{
        width: 100%;
    }

    .ImageContainerH{
        width: 100%;
    }

    .WebDesc {
        font-size: .75em;
    }

    .MainTitle {
        padding: 0 1.5em;
    }

    .footerContent div{
        margin: 0.5em 0;
    }

    .ActivityHolder {
        flex-direction: column;
    }
    .RegisterNowButt {
        margin: 0 1em;
        width: auto;
    }

    .SignUpDisclaimer,.SignUpTitle,.SignUpDescription {
        padding: 0 1em;
    }

    .SignUpTitle {
        font-size: 1.25em;
        text-align: center;
        line-height: 1.5;
    }

    .SignUpDescription {
        font-size: .7em;
    }

    .SignUpDisclaimer {
        font-size: .6em;
    }

    .GreetT {
        font-size: .95em;
    }

    .WebName {
        font-size: 1.15em;
    }

    .SignUpCont{
        flex-direction: column;
    }
    
    .footerContent,.footerHoldCont {
        flex-direction: column;
    }

    .SocialMedia { 
        margin: 0 1em;
    }

    .SignUpDesc {
        width:100%;
        padding: 0;
        text-align: left;
    }

    .DevText {
        font-size: 1em;
    }

    .DevDesc {
        font-size: .8em;
    }

    .SignupImage {
        width: -webkit-fill-available;
    }

    .ListHeader,.LogoGreet { 
        display: none;
    }

    .ActivityContainer {
        box-shadow: none;
        margin:1em 0;
    }

    .ActivityImage {
        border-radius: 0;
    }

    .LogoContClic { 
        margin: .5em auto;
    }

    .ActivityContainer:nth-child(even){
        flex-direction: row-reverse;
    }

    .ListMenuFTitle {
        font-size: 1em;
    }

    .CopyrightDisclaim {
        flex-direction: column;
    }

    .ListMenuFooter,.OfficeLocation,.SocialMedia,.CopyrightDisclaim{
        margin: 0;
        font-size: .85em;
    }

    .LogoImage {
        margin: 0 auto;
    }
}