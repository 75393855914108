.ContentContainer { 
    display: flex;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.TableCont {
    width: 100%;
    background-color: #9c241f;
    padding: 2em .5em;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.TitleWindow {
    padding: 1em .5em !important;
    font-weight: bold;
}

.StorageContainer {
    display: flex;
    align-content: center;
    margin: .5em auto;
    font-size: .75em;
}

.StorageContainer progress,.StorageContainer div{
    margin: auto 0;
}

.StorageContainer div {
    padding: 0 .5em;
}

.OptionController {
    display: flex;
    justify-content: space-between;
}

.ButtonController {
    display: flex;
    margin: 1em 0;
    justify-content: flex-end;
}

.ButtonAddAdmin:hover {
    transform: scale(1.025);
    transition: .2s ease;
    cursor: pointer;
}

.TableAdmin {
    overflow-y:hidden !important;
}

.ButtonAddAdmin {
    border-radius: .5em !important;
    border:0px solid transparent;
    font-weight: bold;
    padding: .5em 2em !important;
}

.TableApps {
    border-spacing: 0 !important;
    width: 100%;
}

.TableApps tr  {
    background-color: azure;
}

.TableApps tr td:nth-child(1) {
    text-align: left;
}

.swalCheckPassForm:has(.swal2-html-container){
    background-color: #333333;
}

.FormValidation {
    font-size: .75em;
}

.TableApps tr td {
    padding: 0 1em;
    font-size: .80em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
    text-overflow: ellipsis;
}

.TableApps tr th {
    padding: 1em;
    font-size: .85em;
    font-weight: bold;
    background-color: #333333 !important;
    color: white;
    text-align: center;
}

.OptionController {
    margin-bottom: 1em;
}

.ButtonController button,.ButtonController select {
    padding: .5em;
    font-weight: bold;
    border: 0px solid transparent;
}

.SearchContainer {
    display: flex;
    width: auto;
}

.SearchImage {
    padding: .5em;
    background-color: white;
    border-radius: .5em 0 0 .5em;
}

.SearchContainer input {
    width: 100%;
    border: 0px solid transparent;
    padding: 0 1.5em;
    border-radius: 0 .5em .5em 0;
}

.TableApps tr:nth-child(even) {
    background-color:#d4d4d4;
}

.TableApps tr:hover {
    background-color: #c0c0c0;
}

.TableApps tr td:last-child {
    text-align: center;
    width: 20%;
}

.ApplicantImage {
    width: 50%;
    margin: 1.5em auto;
    border-radius: 1em;
    background-color: white;
}

.ApplicantImage {
    background-position: center;
    background-size: cover;
}

.ApplicantContainer {
    display: flex;
    background-color: white;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 0%;
}

.OptionContainer svg:hover {
    transform: scale(1.25);
    transition: .2s ease;
    cursor: pointer;
}

.ApplicantBasicData {
    font-size: .85em;
    width: 100%;
    background-color: white;
}

.ApplicantDataTable { 
    width: 100%;
    border-spacing: 0 !important;
}

.ApplicantDataTable tr:nth-child(even) {
    background-color: #d4d4d4;
}

.ApplicantDataTable td {
    padding: .4em 0;
    border-spacing: 0;
    border-top: .15px solid black;
}

.ApplicantBasicData td:nth-child(1){
    padding: .4em .5em;
    font-weight: bold;
}

.ListApplicant_Approved, .ListApplicant_Pending{
    padding: .5em;
    border-radius: .5em;
    font-weight: bold;
    color: white;
}

.TableApps td:has(.ListApplicant_Approved) {
    background-color: green;
}

.TableApps td:has(.ListApplicant_Pending) {
    background-color: #D6A601;
}

.EditData,.DumpData{ 
    padding: .5em;
    height: 2em;
    width: 1.5em;
}

.DumpData:hover,.EditData:hover {
    cursor: pointer;
}