.LoginPageComp {
    width: 25%;
    border-radius: 2em;
}

.LoginPageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.LogoImage { 
    background-size: cover !important;
    background-image: url('../../public/image/miscellanous/logo.png');
    background-repeat: no-repeat;
    padding: 1em;
    width: 5%;
}

.InputLogin {
    background-color: #CECECE;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 0 .5em .5em;
}

.InputLogin div input:focus {
    border:0px solid transparent;
    outline: none;
}

.ButtonContainer button:hover{
    transform: scale(1.05);
    transition: .2s ease;
    cursor: pointer;
}

.ButtonContainer button {
    padding: .5em 2em;
    border-radius: .5em;
    font-weight: bold;
    border: 0px solid transparent;
}

.InputLogin div input{
    padding: .5em 1em;
    border:0px solid transparent;
    border-radius: 0 0 .5em .5em;
}

.userNameContainer {
    margin-top:2em !important;
}

.ButtonContainer {
    margin-bottom: 2em !important;
}

.userNameContainer label,.passwordContainer label {
    padding: .3em .5em;
    background-color: white;
    font-size: .85em;
    font-weight: bold;
    border-radius: .5em .5em 0 0;
}

.NeedHelp {
    font-size: .75em;
    text-align: center;
    background-color: #444444;
    border-radius: 0 0 .5em .5em;
    color: white;
    padding: .5em 0;
}

.userNameContainer,.passwordContainer, .ButtonContainer{
    display: flex;
    width: 70%;
    margin: .5em auto;
    flex-direction: column;
}

.LogoCont {
    background-color: black;
    display: flex;
    justify-content: flex-start;
    padding: .5em 1em;
    border-radius: .5em .5em 0 0;
}

body {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

.LoginSwalForm .SwalIcon svg{
    margin: auto !important;
}

.LoginSwalForm:where(.swal2-container).swal2-backdrop-show {
    background: none !important;
}

.LoginSwalForm .errorSubTitle {
    font-size: .8em;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}