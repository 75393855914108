.ContentContainer { 
    display: flex;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.AdminButtonControl {
    margin: .7em 0 !important;
}

.CloseApplicant {
    display: block !important;
}

.TotalStep {
    display: flex;
    justify-content: center;
    margin: .7em 0;
}

.TotalStep ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    padding: 0 !important;
}

.progress {
    position: relative;
    width: 90%;
    display: flex;
    justify-content: space-between;
    &:before {
      content: "";
      background-color: #cecece;
      position: absolute;
      width: 100%;
      height: 5px;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }
  }
  .progress-bar {
    position: absolute;
    width: 0;
    height: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #11aa75;
    transition: width 0.4s ease;
    z-index: -1;
  }
  .step {
    width: 30px;
    height: 30px;
    background-color: #303030;
    border-radius: 50%;
    border: 4px solid #cecece;
    display: grid;
    place-items: center;
    color: #cecece;
    transition: border-color 0.5s ease-in-out;
    &.active {
      border-color: #11aa75;
      color: #efefef;
    }
  }
  button.button {
    width: 5em;
    height: 2.2em;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #11aa75;
    color: #fff;
    cursor: pointer;
    &[disabled] {
      background-color: #bbb !important;
      cursor: not-allowed;
    }
    &:hover {
      background-color: #22bb80;
    }
    &:active {
      background-color: #119980;
    }
  }

.TotalStep ul li{
    display: block;
    color: white;
    width: 5%;
    border-radius: 1em;
    padding: .2em;
    background-color: #333333;
}

.TitleForm {
    margin: 1em 0;
}

.ApplicantController {
    display: flex;
    justify-content: flex-end;
}

.editAdminApplicant {
    font-size: .95em !important;
}

.ApplicantController svg:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .2s ease;
}

.TableCont {
    width: 100%;
    background-color: #9c241f;
    padding: 2em .5em;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.TitleWindow {
    padding: 1em .5em !important;
    font-weight: bold;
}

.StorageContainer {
    display: flex;
    align-content: center;
    margin: .5em auto;
    font-size: .75em;
}

.StorageContainer progress,.StorageContainer div{
    margin: auto 0;
}

.StorageContainer div {
    padding: 0 .5em;
}

.OptionController {
    display: flex;
    justify-content: space-between;
}

.OptionContainer {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
}

.ButtonController {
    display: flex;
    margin: 1em 0;
    justify-content: flex-end;
}

.TableApps {
    border-spacing: 0 !important;
    width: 100%;
}

.tableHeader_ApplicantData {
    padding: .7em !important;
    text-align: center;
    border:0px solid transparent !important;
    background-color: #333333;
    border-radius: .5em .5em 0 0;
    color: white;
}

.TableApps tr  {
    background-color: azure;
}

.ToyyibPay_Redirect:hover {
    cursor: pointer;
    background-color: #4D3D9A;
}

.ToyyibPay_Redirect {
    padding: 1em;
    margin: .5em 0;
    width: 100%;
    background-color: #624ec4;
    color: white;
    font-weight: bold;
    border:0px solid transparent;
    border-radius: .5em;
}

.TableApps tr td {
    font-size: .80em;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
    text-overflow: ellipsis;
}

.swalPopupEmail {
    display: flex !important;
    flex-direction: column-reverse;
}

.EmailSwal {
    display: flex;
    justify-content: center;
}

.emailcontent {
    font-size: .9em;
}

.emailDesc {
    text-align: center !important;
}

.explicitEmail { 
    font-size: .85em;
}

.TableApps tr th {
    padding: 1em;
    font-size: .85em;
    font-weight: bold;
    background-color: #333333 !important;
    color: white;
    text-align: center;
}

.OptionController {
    margin-bottom: 1em;
}

.ButtonController button,.ButtonController select {
    padding: .5em;
    font-weight: bold;
    border: 0px solid transparent;
}

.emptyTable svg {
    width: 2em !important;
    height: 2em !important;
}

.emptyTable {
    padding: 2em 0 !important;
    font-size: 1.05em !important;
    line-height: 1.25;
    font-weight: 600 !important;
}

.SearchContainer {
    display: flex;
    width: auto;
}

.AddApplicant {
    display: flex;
    margin: 0 .25em;
}

.AddApplicant:hover {
    cursor:pointer;
    transform: scale(1.15);
    transition: .2s ease;
}

.AddApplicant svg {
    width: 1.15em;
    padding: .6em .5em;
    margin: auto 0;
    background-color: white;
    border-radius: .5em;
}

.SearchContainer svg{
    width: 1.15em;
    padding: .6em .5em;
    margin: auto 0;
    background-color: white;
    border-radius: .5em 0 0 .5em;
}

.SearchContainer input {
    width: 100%;
    border: 0px solid transparent;
    padding: 0 1.5em;
    border-radius: 0 .5em .5em 0;
}

.TableApps tr:nth-child(even) {
    background-color:#d4d4d4;
}

.TableApps tr:hover {
    background-color: #c0c0c0;
}

.TableApps tr td:last-child {
    text-align: center;
}

.ApplicantImage {
    width: 50%;
    margin: 1.5em auto;
    border-radius: 1em;
    background-color: white;
}

.ApplicantImage {
    background-position: center;
    background-size: cover;
}

.ApplicantContainer {
    display: flex;
    background-color: white;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 0%;
}

.OptionContainer svg {
    padding : 1em .5em !important;
}

.OptionContainer svg:hover {
    transform: scale(1.25);
    transition: .2s ease;
    cursor: pointer;
}

.ApplicantBasicData {
    font-size: .85em;
    width: 100%;
    background-color: white;
}

body {
    padding: 0 !important;
}

.FinishEdit {
    display: none;
}

.ApplicantDataTable { 
    width: 100%;
    border-spacing: 0 !important;
}

.ApplicantDataTable tr:nth-child(even) {
    background-color: #d4d4d4;
}

.ApplicantDataTable td {
    padding: .6em 0;
    border-spacing: 0;
    border-top: .15px solid black;
}

.SearchFilter {
    display: flex;
}

.ApplicantBasicData td:nth-child(1){
    padding: 0 .5em;
    font-weight: bold;
}

.SelectFilter {
    display: flex;
}

.SelectFilter div {
    margin: auto 0;
    padding: .5em;
    border-radius: .5em 0 0 .5em;
    background-color: white;
}

.SelectFilter select:last-child {
    border-radius: 0 .5em .5em 0;
}

.LogOutButtContainer {
    display: flex;
}

.editSwalAdmins {
    margin: 0 auto !important;
}

.SelectFilter select {
    padding: .5em 1em;
    border: 0px solid transparent;
}

.ListApplicant_Approved, .ListApplicant_Pending{
    padding: .5em;
    border-radius: .5em;
    font-weight: bold;
    margin: auto;
    color: white;
}

.TableApps td:has(.ListApplicant_Approved) {
    background-color: green;
}

.TableApps td:has(.ListApplicant_Pending) {
    background-color: #D6A601;
}

.ViewData,.DumpData, .VerifyData,.PendData,.SendEmail{ 
    padding: .5em;
    height: 2em;
    width: 1.5em;
}

.DumpData:hover,.ViewData:hover,.VerifyData:hover,.PendData:hover,.SendEmail:hover {
    cursor: pointer;
}